import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../../ui/components/Layout/Wrapper";
import { MOCKS } from "../../Telemedicine/mocks/solutions";
import { Styled } from "./styles";

type SolutionsProps = {
  service: "telemedicine" | "virtucare" | "fall-sensor";
};

export const Solutions: React.FC<SolutionsProps> = ({ service }) => {
  const { t } = useTranslation();

  const getMocks = () => {
    const mock = {
      telemedicine: MOCKS.telemedicine_solutions,
      virtucare: MOCKS.virtucare_solutions,
      "fall-sensor": MOCKS.virtucare_fall_sensor,
    };

    return mock[service];
  };

  return (
    <Wrapper>
      <Styled.FeaturesContainer>
        <h2>{t(`services.${service}.solution.title`)}</h2>

        <Styled.SolutionsContainer>
          {getMocks().map((solution) => {
            return (
              <Styled.SolutionItem key={solution.title}>
                <img src={solution.icon} alt={solution.title} />
                <span>{t(`services.${solution.title}`)}</span>
              </Styled.SolutionItem>
            );
          })}
        </Styled.SolutionsContainer>
      </Styled.FeaturesContainer>
    </Wrapper>
  );
};
