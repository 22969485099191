import { AiOutlineAlert } from "react-icons/ai";
import { FaStethoscope } from "react-icons/fa";
import { HiOutlineDesktopComputer } from "react-icons/hi";

export type IconVariants =
  | "AiOutlineAlert"
  | "FaStethoscope"
  | "HiOutlineDesktopComputer";

export const getSolutionIcon = (iconName: IconVariants) => {
  const Icon =
    iconName === "AiOutlineAlert"
      ? AiOutlineAlert
      : iconName === "FaStethoscope"
      ? FaStethoscope
      : HiOutlineDesktopComputer;

  return { Icon };
};
