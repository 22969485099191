import styled from "styled-components";
import DarkBackgroundPNG from "../../assets/png/home-banner-dark.png";

const ProfileContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  padding-bottom: 36px;

  color: ${({ theme }) => theme.colors.paragraph};
  background: ${({ theme }) => theme.colors.bg};
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 325px;
  padding: 102px 0 76px;

  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.bg};
  background-image: ${`url(${DarkBackgroundPNG});`};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    height: 230px;
    padding: 78px 0 56px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 210px;
    text-align: center;
  }

  > div {
    padding: 0 60px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0 16px;
    }

    img {
      width: 242px;
      margin-bottom: 22px;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        width: 200px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 170px;
      }
    }

    h1 {
      font: ${({ theme }) => theme.fonts.title};
      color: white;

      > strong {
        margin-left: 5px;
        color: ${({ theme }) => theme.colors.tertiary[500]};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 30px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 20px;
      }
    }
  }
`;

const Bar = styled.div`
  height: 28px;
  width: 100%;

  background: ${({ theme }) => theme.colors.shape};
`;

const ProfileContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 48px;

  padding: 16px 65px 0;

  color: ${({ theme }) => theme.colors.paragraph};
  font: ${({ theme }) => theme.fonts.small};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-inline: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-inline: 24px;
  }
`;

const ProfileCardWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-bottom: 36px;
  }
`;

const AboutVirtumedWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
  }
`;

const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }

  &.top {
    display: none;
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      top: -14px;
      right: 50%;
      transform: translateX(50%);
    }
  }
`;

export const Styled = {
  ProfileContainer,
  HeaderContainer,
  ProfileContent,
  Bar,
  ProfileCardWrapper,
  AboutVirtumedWrapper,
  SettingsWrapper,
};
