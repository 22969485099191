import styled from "styled-components";
import { borderGradient } from "../../utils";

const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 288px;
  max-height: 512px;
  padding: 56px 42px 32px;
  text-align: center;

  border-radius: 10px;
  border: 1px solid transparent;
  background: ${({ theme }) => borderGradient(theme)};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 360px;
    padding: 48px 34px 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 320px;
  }

  > img {
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 36px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 32px;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    background: none;
    padding: 8px;

    color: ${({ theme }) => theme.colors.paragraph};

    border-radius: 6px;
    border: 1px solid transparent;
    background: ${({ theme }) => borderGradient(theme)};
  }
`;

const IdentityContainer = styled.div`
  margin-bottom: 52px;

  h3 {
    font: ${({ theme }) => theme.fonts.subTitle};
    width: 208px;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 52px;
`;

export const Styled = {
  ProfileCard,
  IdentityContainer,
  ContactContainer,
};
