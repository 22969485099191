import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Layout } from "../../ui/components/Layout";
import { Wrapper } from "../../ui/components/Layout/Wrapper";
import { ContactUsForm } from "./components/ContactUsForm";
import { ContactUsFormMobile } from "./components/ContactUsForm/mobile";
import { Styled } from "./styles";

export const Contact: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Layout
      withFaqBanner={false}
      headerConfig={{ alwaysWhiteNavigationLabel: false }}
    >
      <div style={{ position: "relative", zIndex: 1 }}>
        <Wrapper>
          <Styled.ContactContainer>
            <h1>{t("contact.title")}</h1>
            <p>{t("contact.description")}</p>

            {isMobile ? <ContactUsFormMobile /> : <ContactUsForm />}
          </Styled.ContactContainer>
        </Wrapper>
      </div>
    </Layout>
  );
};
