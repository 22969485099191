import styled from "styled-components";

export const WrapperContainer = styled.div`
  position: relative;
  z-index: 3;

  max-width: 90rem;

  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

export const Styled = {
  WrapperContainer,
};
