import styled from "styled-components";

type NavigationLinkProps = {
  isCurrent?: boolean;
  // whiteLabel: boolean;
};

export const NavigationLink = styled.li<NavigationLinkProps>`
  position: relative;

  a {
    font: ${({ theme }) => theme.fonts.paragraph};
    text-decoration: none;

    transition: filter 200ms ease-in-out;
    &:hover {
      filter: brightness(0.8);
    }
  }

  label.link-highlight {
    ::before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.colors.secondary[500]};
      opacity: ${({ isCurrent }) => (isCurrent ? 1 : 0)};
    }
  }
`;

export const Styled = {
  NavigationLink,
};
