import { HTMLAttributes } from "react";
import { ImWhatsapp } from "react-icons/im";
import { useContact } from "../../../hooks/useWpp";
import { Styled } from "./styles";

type WppButtonProps = HTMLAttributes<HTMLButtonElement> & {};

export const WppButton: React.FC<WppButtonProps> = ({ ...rest }) => {
  const { onContact } = useContact();

  return (
    <Styled.WppContainer onClick={onContact} {...rest}>
      <ImWhatsapp />
    </Styled.WppContainer>
  );
};
