import { useTranslation } from "react-i18next";
import MockupSitePNG from "../../../../assets/png/mockupsite.png";
import VirtumedOutlined from "../../../../assets/svg/virtumed-outlined.svg";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { useContact } from "../../../../hooks/useWpp";
import { Button } from "../../Form/Button";
import { Wrapper } from "../Wrapper";
import { Styled } from "./styles";

type FaqBannerProps = {
  withMockup?: boolean;
};

export const FaqBanner: React.FC<FaqBannerProps> = ({ withMockup = false }) => {
  const { t } = useTranslation();
  const { isMobile, width } = useIsMobile();
  const { onContact } = useContact();

  return (
    <Styled.FaqBannerContainer>
      <Wrapper>
        <Styled.FaqBannerContent>
          <Styled.FaqBannerTextsWrapper>
            <label className="divider" />
            <h3>{t("global.faq.title")}</h3>

            <p>{t("global.faq.explanatory-text")}</p>

            <Button
              variant="outlined"
              size={!isMobile ? "medium" : width <= 375 ? "xs" : "small"}
              label={t("global.faq.button-label")}
              onClick={onContact}
            />
          </Styled.FaqBannerTextsWrapper>

          <Styled.FaqBannerImagesWrapper withMockup={withMockup}>
            {withMockup && (
              <img src={MockupSitePNG} alt="mockup" className="mockup" />
            )}
            <img src={VirtumedOutlined} alt="virtumed" />
          </Styled.FaqBannerImagesWrapper>
        </Styled.FaqBannerContent>
      </Wrapper>
    </Styled.FaqBannerContainer>
  );
};
