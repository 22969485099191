import styled from "styled-components";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 38px;

  > img {
    width: 100%;
    max-width: 760px;
  }

  > h1 {
    font: ${({ theme }) => theme.fonts.title};
    padding: 0 20px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 24px;
    }
  }
`;

export const Styled = {
  NotFoundContainer,
};
