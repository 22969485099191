import styled from "styled-components";
import { getHeaderBackgroundColor, getHeaderSize } from "./helpers";
import type { StyledProps } from "./types";

export const LayoutContainer = styled.main<StyledProps>`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  min-width: 100vw;

  background: ${({ theme }) => theme.colors.bg};

  &::before,
  &.with-banner::before,
  &.with-larger-banner::before {
    content: "";
    position: absolute;
    z-index: 0;

    width: 100vw;
    height: ${({ headerSize }) => getHeaderSize(headerSize)};

    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-color: ${({ backgroundVariant, theme, isDarkMode }) =>
      backgroundVariant &&
      getHeaderBackgroundColor({ backgroundVariant, theme, isDarkMode })};

    ${({ isDarkMode, headerBanner }) => {
      if (headerBanner) {
        if (headerBanner?.dark) {
          return `background-image: url(${
            isDarkMode ? headerBanner.dark : headerBanner.light
          });`;
        }

        return `background-image: url(${headerBanner.light});`;
      }
    }}
  }

  &::before {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      height: ${({ headerSize }) =>
        `calc(${getHeaderSize(headerSize)} - 16rem)`};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: ${({ headerSize }) =>
        `calc(${getHeaderSize(headerSize)} - 26rem)`};
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: ${({ headerSize }) =>
        `calc(${getHeaderSize(headerSize)} - 29rem)`};
    }
  }

  &.with-banner {
    ::before {
      @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 4rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} -  10rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 7rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 11.5rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 15.75rem)`};
      }
    }
  }

  &.with-larger-banner {
    ::before {
      @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 3rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} -  11rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 7.5rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 9.75rem)`};
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        height: ${({ headerSize }) =>
          `calc(${getHeaderSize(headerSize)} - 13rem)`};
      }
    }
  }
`;

export const Styled = {
  LayoutContainer,
};
