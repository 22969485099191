import styled from "styled-components";
import { borderGradient } from "../../utils";

export const AboutVirtumedContainer = styled.div`
  width: 280px;
  height: 432px;
  padding: 32px 24px;

  border: 1px solid transparent;
  border-radius: 10px;
  background: ${({ theme }) => borderGradient(theme)};

  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 360px;
    height: 412px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 320px;
    height: 394px;
  }

  img {
    margin-bottom: 50px;
  }

  p {
    font: ${({ theme }) => theme.fonts.small};
    font-size: 14px;
    margin-bottom: 28px;
  }
`;

export const Styled = {
  AboutVirtumedContainer,
};
