import styled from "styled-components";

const TelemedicineContainer = styled.section`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  padding: 130px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 90px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 80px 0 58px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 40px 0 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 20px 0 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    color: ${({ theme }) => theme.colors.paragraph};

    padding: 0 70px;
    margin-bottom: 0px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding-inline: 60px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-inline: 40px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-inline: 26px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding-inline: 24px;
    }

    h1 {
      font: ${({ theme }) => theme.fonts.title};
      margin-bottom: 24px;

      @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
        font-size: 30px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 28px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 26px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 22px;
        margin-bottom: 12px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    p {
      font: ${({ theme }) => theme.fonts.paragraph};
      max-width: 650px;
      margin-bottom: 38px;

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 16px;
        max-width: 600px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 14px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 12px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        font-size: 10px;
        margin-bottom: 16px;
      }
    }
  }
`;

const Mockup = styled.img`
  position: absolute;
  right: -180px;
  top: -40px;

  z-index: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    width: 640px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 540px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    top: calc(100% - 50px);
    right: -50px;

    width: 320px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: calc(100% - 50px);
    right: -40px;

    width: 240px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: calc(100% - 32px);
    right: -40px;

    width: 200px;
  }
`;

export const Styled = {
  TelemedicineContainer,
  Mockup,
};
