import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { useContact } from "../../../../../hooks/useWpp";
import { Button } from "../../../../../ui/components/Form/Button";
import SolutionsMock from "./mock/solutions.json";
import { SolutionCard } from "./SolutionCard";
import { Styled } from "./styles";

export const SolutionsSection: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, width } = useIsMobile();

  const { onContact } = useContact();

  return (
    <Styled.SolutionsSection>
      <h2>{t("home.solutions.title")}</h2>
      <p>{t("home.solutions.description")}</p>

      <Styled.CarrouselSolutionWrapper>
        {SolutionsMock.solutions.map((solution) => {
          return <SolutionCard key={solution.icon} solution={solution} />;
        })}
      </Styled.CarrouselSolutionWrapper>

      <Button
        label={t("home.solutions.button-label")}
        size={!isMobile ? "medium" : width <= 375 ? "xs" : "small"}
        onClick={onContact}
      />
    </Styled.SolutionsSection>
  );
};
