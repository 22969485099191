import { useTranslation } from "react-i18next";
import TelemedicineMockupPNG from "../../../assets/png/telemedicine-mockup.png";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useContact } from "../../../hooks/useWpp";
import { Button } from "../../../ui/components/Form/Button";
import { Layout } from "../../../ui/components/Layout";
import { Wrapper } from "../../../ui/components/Layout/Wrapper";
import { HowItWorks } from "../components/HowItWorks";
import { Solutions } from "../components/Solutions";
import { SolutionsMobile } from "../components/Solutions/mobile";
import { Styled } from "./styles";

export const Telemedicine: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const { onContact } = useContact();
  return (
    <Layout
      headerConfig={{
        headerBackgroundVariant: "tertiary",
        headerSize: "medium",
      }}
      className="with-banner"
    >
      <Styled.TelemedicineContainer>
        <Wrapper>
          <div>
            <h1>{t("services.telemedicine.title")}</h1>
            <p>{t("services.telemedicine.description")}</p>

            <Button
              label={t("services.telemedicine.button-label")}
              size={isMobile ? "xs" : "small"}
              onClick={onContact}
            />
          </div>

          <Styled.Mockup src={TelemedicineMockupPNG} alt="mockup" />
        </Wrapper>
      </Styled.TelemedicineContainer>

      {isMobile ? (
        <SolutionsMobile service="telemedicine" gridColumns={2} />
      ) : (
        <Solutions service="telemedicine" />
      )}
      <HowItWorks service="telemedicine" />
    </Layout>
  );
};
