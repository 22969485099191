import Icon1 from "../../../../../../assets/svg/solution/1.svg";
import Icon10 from "../../../../../../assets/svg/solution/10.svg";
import Icon2 from "../../../../../../assets/svg/solution/2.svg";
import Icon3 from "../../../../../../assets/svg/solution/3.svg";
import Icon4 from "../../../../../../assets/svg/solution/4.svg";
import Icon5 from "../../../../../../assets/svg/solution/5.svg";
import Icon9 from "../../../../../../assets/svg/solution/9.svg";

const telemedicine = [
  {
    description: "telemedicine.1",
    icon: Icon1,
  },
  {
    description: "telemedicine.2",
    icon: Icon2,
  },
  {
    description: "telemedicine.3",
    icon: Icon3,
  },
  {
    description: "telemedicine.4",
    icon: Icon4,
  },
  {
    description: "telemedicine.5",
    icon: Icon10,
  },
  // {
  //   description: "tel.6",
  //   icon: Icon6,
  // },
];

const virtucare = [
  {
    description: "virtucare.1",
    icon: Icon1,
  },
  {
    description: "virtucare.3",
    icon: Icon3,
  },
  {
    description: "virtucare.2",
    icon: Icon2,
  },
  {
    description: "virtucare.4",
    icon: Icon10,
  },
  {
    description: "virtucare.5",
    icon: Icon5,
  },
];

const fallSensor = [
  {
    description: "fall-sensor.1",
    icon: Icon1,
  },
  {
    description: "fall-sensor.3",
    icon: Icon3,
  },
  {
    description: "fall-sensor.4",
    icon: Icon9,
  },
  {
    description: "fall-sensor.2",
    icon: Icon4,
  },
];

export const Solutions = {
  telemedicine,
  virtucare,
  fallSensor,
};
