export type SizeTypes = "xs" | "small" | "medium" | "large";

type GetSizesProps = {
  size: SizeTypes;
};

export const getSizes = ({ size }: GetSizesProps) => {
  const sizes = {
    xs: {
      padding: "6px 20px",
      "font-size": "12px",
    },

    small: {
      padding: "8px 24px",
      "font-size": "14px",
    },
    medium: {
      padding: "10px 20px",
      "font-size": "16px",
    },
    large: {
      padding: "10px 24px",
      "font-size": "20px",
    },
  };

  return sizes[size];
};
