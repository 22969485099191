import { useTranslation } from "react-i18next";
import WppSVG from "../../../../assets/svg/wpp.svg";
import { formatterPhoneNumber } from "../../../../ultils/formatterPhoneNumber";
import type { ProfileType } from "../../mocks";
import { Styled } from "./styles";

type ProfileCardMock = {
  data: ProfileType;
};

export const ProfileCard: React.FC<ProfileCardMock> = ({ data }) => {
  const { t } = useTranslation();

  const wppPhoneNumber = formatterPhoneNumber({
    phoneNumber: String(data.phone),
  });

  const handleCLick = async () => {
    window.open(`https://api.whatsapp.com/send?phone=${data.phone}`, "_blank");
  };

  return (
    <Styled.ProfileCard>
      <img src={data.avatar} alt={data.name} />

      <Styled.IdentityContainer>
        <h3>{data.name}</h3>
        <span>{data.title}</span>
      </Styled.IdentityContainer>

      <Styled.ContactContainer>
        <span>{data.email}</span>
        <span>{wppPhoneNumber}</span>
      </Styled.ContactContainer>

      <button onClick={handleCLick}>
        <img src={WppSVG} alt="wpp" />
        {t("profile.contact-wpp")}
      </button>
    </Styled.ProfileCard>
  );
};
