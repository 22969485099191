import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  padding: 0 60px;

  font: ${({ theme }) => theme.fonts.info};
  color: ${({ theme }) => theme.colors.info};

  transition: color 0.2s ease-in;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-inline: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-inline: 26px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-inline: 24px;
  }
`;

export const ArticleContainer = styled.article`
  max-width: 463px;
  text-align: left;

  > p {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 10px;
    }
  }

  img {
    margin-bottom: 2.375rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 150px;
      margin-bottom: 18px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 120px;
      margin-bottom: 16px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      width: 104px;
      margin-bottom: 12px;
    }
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  gap: 1.625rem;

  margin-top: 1.5rem;
  margin-bottom: 2.625rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    gap: 0.825rem;
    margin: 16px 0;
  }

  a {
    color: ${({ theme }) => theme.colors.info};
    > svg {
      font-size: 20px;
      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        font-size: 12px;
      }
    }
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;

  flex: 1;
  margin-left: 128px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }

  > div {
    display: flex;
    flex-direction: column;

    height: 100%;

    > strong {
      margin-bottom: 1.875rem;
    }

    > ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      padding-bottom: 34px;
      list-style: none;

      a {
        color: ${({ theme }) => theme.colors.info};
        text-decoration: none;

        transition: color 0.2s ease-in-out;
      }
    }
  }
`;

export const CopyrightContainer = styled.div`
  width: 100vw;
  padding: 1.875rem 0;

  border-top: 2px solid ${({ theme }) => theme.colors.shape};
  text-align: center;

  transition: border-color 0.2s;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 10px 0;
    font-size: 8px;
  }
`;

export const Styled = {
  FooterContainer,
  ArticleContainer,
  SocialContainer,
  LinksContainer,
  CopyrightContainer,
};
