import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { API_MAIL_URL } from "../../config/constants";
import { toastTrigger } from "../../hooks/useToastTrigger";
import type { FormType } from "./types";

const schema = yup
  .object({
    name: yup
      .string()
      .trim()
      .min(3, "min.3")
      .max(30, "max.30")
      .required("required"),
    last_name: yup.string().max(80, "max.80"),
    email: yup
      .string()
      .trim()
      .email("email")
      .max(60, "max.60")
      .required("required"),
    phone_number: yup.string().trim().max(15, "max.15"),
    subject: yup
      .string()
      .trim()
      .min(3, "min.3")
      .max(100, "max.80")
      .required("required"),
    message: yup
      .string()
      .trim()
      .min(3, "min.3")
      .max(500, "max.500")
      .required("required"),
  })
  .required();

export const useContact = () => {
  const {
    formState: { errors, isSubmitSuccessful },
    ...formData
  } = useForm<FormType>({ resolver: yupResolver(schema) });

  const onSubmit = formData.handleSubmit(
    useCallback((data) => {
      console.log(API_MAIL_URL);

      fetch(API_MAIL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nome: data.name,
          sobrenome: data.last_name,
          email: data.email,
          telefone: data.phone_number,
          assunto: data.subject,
          mensagem: data.message,
        }),
      })
        .then((res: any) => {
          if (res.status === 200) {
            toastTrigger({
              type: "success",
              message: "E-mail enviado com sucesso!",
            });
          }
        })
        .catch((err) => {
          toastTrigger({
            type: "error",
            message: "Erro ao enviar e-mail!",
          });
          console.error(err);
        });
    }, [])
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      formData.reset({
        name: "",
        last_name: "",
        email: "",
        phone_number: "",
        subject: "",
        message: "",
      });
    }
  }, [isSubmitSuccessful, formData.reset, formData]);

  return {
    onSubmit,
    errors,
    ...formData,
  };
};
