import styled from "styled-components";

const WppContainer = styled.button`
  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-10px);
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 4;
  bottom: 40px;
  right: 40px;

  width: 50px;
  height: 50px;

  background-color: #25d366;
  border: 3px solid #fff;
  color: #fff;
  text-align: center;
  font-size: 30px;

  border-radius: 50%;
  box-shadow: 2px 2px 3px #ccc;

  animation: float 3s ease-in-out infinite;

  :hover {
    filter: none;
  }

  > svg {
    fill: #fff;
    font-size: 24px;
  }
`;

export const Styled = {
  WppContainer,
};
