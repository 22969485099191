import styled from "styled-components";

export const ProductsBenefitsContainer = styled.div`
  position: relative;
  display: flex;

  padding: 0 46px;
  width: 100%;
  height: 218px;

  border-radius: 0 10px 10px;

  background: ${({ theme }) => theme.colors.shape};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 22px;
  }

  &.mobile {
    border-radius: 10px;
    padding-top: 36px;
    margin-bottom: 80px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 14px 10px;
      height: 140px;

      padding-top: 56px;
      align-items: flex-start;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding: 12px 8px;
      height: 116px;
      gap: 8px;

      padding-top: 36px;
      align-items: flex-start;
    }
  }
`;

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    &.benefit-3,
    &.benefit-4 {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: 6px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    gap: 4px;
  }

  > img {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: 26px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 20px;
    }
  }

  > span {
    text-align: center;
    font: ${({ theme }) => theme.fonts.small};
    color: ${({ theme }) => theme.colors.info};
    max-width: 200px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 12px;
      max-width: 180px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 10px;
      max-width: none;
    }

    /* @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 10px;
    } */
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: -18px;
  right: 30px;

  &.mobile {
    bottom: -80px;
    left: calc(50% - 60px);

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      bottom: -70px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      bottom: -70px;
    }
  }
`;

export const Styled = {
  ProductsBenefitsContainer,
  ProductCard,
  ButtonWrapper,
};
