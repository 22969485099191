import { useTranslation } from "react-i18next";
import { HiOutlinePhone } from "react-icons/hi";
import { MdMailOutline } from "react-icons/md";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { Button } from "../../../../ui/components/Form/Button";
import { InputField } from "../../../../ui/components/Form/InputField";
import { TextAreaField } from "../../../../ui/components/Form/TextAreaField";
import { useContact } from "../../useContact";
import { Styled } from "./styles";

export const ContactUsFormMobile: React.FC = () => {
  const { t } = useTranslation();
  const { errors, onSubmit, register } = useContact();
  const { width } = useIsMobile();

  return (
    <Styled.FormContainer className="mobile">
      <Styled.InstructionsContainer className="mobile">
        <h3>{t("contact.instruction-title")}</h3>
        <span>{t("contact.instruction-description")}</span>

        <Styled.InfoContactArea>
          <span>
            <HiOutlinePhone />+ 55 11 96896-9999
          </span>
          <span>
            <MdMailOutline />
            atendimento@virtumed.com.br
          </span>
        </Styled.InfoContactArea>

        <Styled.Form onSubmit={onSubmit}>
          <h3>{t("contact.form.title")}</h3>

          <InputField
            label={t("contact.form.name")}
            id="name"
            error={errors?.name}
            required
            {...register("name")}
          />

          <InputField
            label={t("contact.form.last_name")}
            id="last_name"
            error={errors?.last_name}
            {...register("last_name")}
          />

          <InputField
            label={t("contact.form.email")}
            id="email"
            error={errors?.email}
            required
            {...register("email")}
          />

          <InputField
            label={t("contact.form.phone_number")}
            id="phone_number"
            error={errors?.phone_number}
            {...register("phone_number")}
          />

          <InputField
            label={t("contact.form.subject")}
            id="subject"
            required
            error={errors?.subject}
            {...register("subject")}
          />

          <TextAreaField
            label={t("contact.form.message")}
            id="message"
            required
            error={errors?.message}
            {...register("message")}
          />
          <Styled.ButtonWrapper>
            <Button
              label={t("contact.form.submit-label")}
              size={width <= 768 ? "xs" : "small"}
            />
          </Styled.ButtonWrapper>
        </Styled.Form>
      </Styled.InstructionsContainer>
    </Styled.FormContainer>
  );
};
