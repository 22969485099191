import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { Wrapper } from "../../../../../ui/components/Layout/Wrapper";
import { ProductsBenefits } from "../../ProductsBenefits";
import { ProductsBenefitsMobile } from "../../ProductsBenefits/mobile";
import { Styled } from "./styles";

export const MainSection: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Wrapper>
      <Styled.MainSectionContainer>
        <h1>
          {t("home.main.title-1")}
          <br />
          <strong>{t("home.main.title-2")}</strong>
        </h1>
        <p>{t("home.main.description")}</p>

        <Styled.ProductsBenefitsWrapper>
          {isMobile ? <ProductsBenefitsMobile /> : <ProductsBenefits />}
        </Styled.ProductsBenefitsWrapper>
      </Styled.MainSectionContainer>
    </Wrapper>
  );
};
