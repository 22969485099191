import { useDropdown } from "../../../../hooks/useDropdown";
import { useIsCurrentPage } from "../../../../hooks/useIsCurrentPage";
import { DropdownLink } from "./DropdownLink";
import servicesUrls from "./mock.json";
import { Styled } from "./styles";

type DropdownProps = {
  label: string;
  whiteLabel: boolean;
};

export const Dropdown: React.FC<DropdownProps> = ({ label, whiteLabel }) => {
  const { handleClick, isOpened, dropdownRef } = useDropdown();
  const { isCurrentPage } = useIsCurrentPage({ url: "service/", label });

  return (
    <Styled.DropdownContainer
      isCurrentPage={isCurrentPage}
      whiteLabel={whiteLabel}
    >
      <button onClick={handleClick}>{label}</button>

      {isOpened && (
        <Styled.Dropdown ref={dropdownRef} id="dropdown">
          {servicesUrls.map((link) => {
            return <DropdownLink {...link} key={link.url} />;
          })}
        </Styled.Dropdown>
      )}
    </Styled.DropdownContainer>
  );
};
