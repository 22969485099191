import styled from "styled-components";

type Props = {
  whiteLabel: boolean;
};

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  z-index: 2;

  width: 100%;
  padding: 2.375rem 4.875rem 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 2.375rem 3.75rem 0;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;

  width: 100%;
`;

const LinksList = styled.ul<Props>`
  display: flex;
  gap: 3rem;
  list-style: none;

  * {
    color: ${({ whiteLabel, theme }) =>
      whiteLabel ? `white` : theme.colors.paragraph};
  }
`;

const MenuWrapper = styled.nav`
  display: none;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

const MenuButton = styled.button<Props>`
  width: 30px;
  height: 30px;

  font-size: 30px;
  color: ${({ whiteLabel, theme }) =>
    whiteLabel ? `white` : theme.colors.paragraph};

  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  border-radius: 6px;
`;

const MenuContainer = styled.div``;
const MenuList = styled.div``;

export const Styled = {
  HeaderContainer,
  NavWrapper,
  LinksList,
  //
  MenuWrapper,
  MenuButton,
  MenuContainer,
  MenuList,
};
