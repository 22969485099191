import { SolutionItem } from "./Solution";
import { Styled } from "./styles";

export const SolutionsList: React.FC = () => {
  const solutionsMock = [
    {
      name: "telemedicine",
      url: "/service/telemedicine",
    },
    {
      name: "virtucare",
      url: "/service/virtucare",
    },
    {
      name: "fall-sensor",
      url: "/service/fall-sensor",
    },
  ];

  return (
    <Styled.SolutionsList>
      {solutionsMock.map((solution) => {
        return <SolutionItem key={solution.name} {...solution} />;
      })}
    </Styled.SolutionsList>
  );
};
