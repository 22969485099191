import styled from "styled-components";

const SolutionsList = styled.div`
  flex: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 360px;
  }
`;

export const Styled = {
  SolutionsList,
};
