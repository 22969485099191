import styled from "styled-components";

type Props = {
  gridColumns: number;
};

const FeaturesContainer = styled.section`
  width: 100%;
  margin-top: 50px;
  padding: 0 80px;

  color: ${({ theme }) => theme.colors.paragraph};

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-inline: 54px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-inline: 40px;
    margin-top: 34px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-inline: 26px;
    margin-top: 26px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-inline: 24px;
    margin-top: 26px;
  }

  h2 {
    font: ${({ theme }) => theme.fonts.subTitle};
    font-size: 30px;
    max-width: 307px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 26px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 22px;
      max-width: 290px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 16px;
      max-width: 200px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 14px;
      max-width: 160px;
    }
  }
`;

const SolutionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 28px;

  margin-top: 80px;
  padding-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    margin-top: 60px;
    gap: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-top: 40px;
  }
`;

const SolutionsContainerMobile = styled.div<Props>`
  margin: 80px 0 40px;

  display: grid;
  grid-template-columns: ${({ gridColumns }) => `repeat(${gridColumns}, 1fr)`};
  grid-column-gap: 20px;
  grid-row-gap: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column-gap: 12px;
    grid-row-gap: 8px;
    margin-top: 50px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    grid-column-gap: 10px;
    grid-row-gap: 6px;
    margin-top: 40px;
  }
`;

const SolutionItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  height: 142px;
  padding: 20px 10px;

  border: 2px solid ${({ theme }) => theme.colors.primary[500]};
  border-radius: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding: 14px;
    height: 126px;
    width: 210px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 14px;
    height: 126px;
    width: 160px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 14px;
    height: 70px;
    width: 100%;

    flex-direction: row;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 12px;
    height: 60px;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    border-width: 1px;
    padding: 12px;
    height: 50px;
    width: 100%;
  }

  > span {
    text-align: center;
    font: ${({ theme }) => theme.fonts.small};
    max-width: 200px;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      /* font-size: 10px; */
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 10px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 8px;
    }
  }

  > img {
    height: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      height: 32px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: 24px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      height: 20px;
    }
  }
`;

export const Styled = {
  FeaturesContainer,
  SolutionItem,
  SolutionsContainer,
  SolutionsContainerMobile,
};
