import { useTranslation } from "react-i18next";
import DarkLogoSVG from "../../../../assets/svg/dark-logo.svg";
import LightLogoSVG from "../../../../assets/svg/light-logo.svg";
import { useTheme } from "../../../../hooks/useTheme";
import { Styled } from "./styles";

export const AboutVirtumed: React.FC = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();

  return (
    <Styled.AboutVirtumedContainer>
      <img src={isDarkMode ? LightLogoSVG : DarkLogoSVG} alt="virtumed" />
      <p>{t(`profile.virtumed.description.1`)}</p>
      <p>{t(`profile.virtumed.description.2`)}</p>
    </Styled.AboutVirtumedContainer>
  );
};
