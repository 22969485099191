import { useTranslation } from "react-i18next";
import { getSolutionIcon, IconVariants } from "./helpers/getSolutionIcon";
import { Styled } from "./styles";

type SolutionCardProps = {
  solution: {
    icon: string;
    name: string;
  };
};

export const SolutionCard: React.FC<SolutionCardProps> = ({ solution }) => {
  const { t } = useTranslation();
  const { Icon } = getSolutionIcon(solution.icon as IconVariants);

  return (
    <Styled.SolutionCardWrapper>
      <Styled.IconWrapper>
        <Icon />
      </Styled.IconWrapper>

      <h3>{t(`home.solutions.${solution.name}.title`)}</h3>
      <span>{t(`home.solutions.${solution.name}.description`)}</span>
    </Styled.SolutionCardWrapper>
  );
};
