import { Solutions } from "./mocks/telemedicine";
import { Solution } from "./types";

export const getSolutions = (solution: Solution) => {
  const solutions = {
    telemedicine: Solutions.telemedicine,
    virtucare: Solutions.virtucare,
    fallSensor: Solutions.fallSensor,
  };

  return solutions[solution];
};
