import { useIsMobile } from "../../../hooks/useIsMobile";
import { useTheme } from "../../../hooks/useTheme";
import { WppButton } from "../WppButton";
import { FaqBanner } from "./FaqBanner";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { HeaderMobile } from "./Mobile/HeaderMobile";
import { LayoutContainer } from "./styles";
import type { LayoutProps } from "./types";

export const Layout: React.FC<LayoutProps> = ({
  children,
  withFaqBanner = true,
  headerConfig,
  className,
}) => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useIsMobile();

  return (
    <LayoutContainer
      isDarkMode={isDarkMode}
      headerBanner={headerConfig?.headerBanner}
      headerSize={headerConfig?.headerSize || "large"}
      backgroundVariant={headerConfig?.headerBackgroundVariant}
      className={className}
    >
      {isMobile ? (
        <HeaderMobile
          alwaysWhiteNavigationLabel={
            headerConfig?.alwaysWhiteNavigationLabel || false
          }
        />
      ) : (
        <Header
          alwaysWhiteNavigationLabel={
            headerConfig?.alwaysWhiteNavigationLabel || false
          }
        />
      )}
      <WppButton />

      {children}

      {withFaqBanner && <FaqBanner withMockup />}
      <Footer />
    </LayoutContainer>
  );
};
