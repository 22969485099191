import styled from "styled-components";

type Props = {
  error?: boolean;
};

export const TextAreaFieldContainer = styled.div<Props>`
  display: flex;
  flex: 1;
  flex-direction: column;

  font: ${({ theme }) => theme.fonts.small};
  color: ${({ theme }) => theme.colors.paragraph};

  label {
    font-size: 12px;
    margin-bottom: 4px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 10px;
    }

    strong {
      margin-left: 4px;
      color: ${({ theme }) => theme.colors.error};
      font-size: 14px;

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 12px;
      }
    }
  }

  span {
    color: ${({ theme, error }) => error && theme.colors.error};

    ::first-letter {
      text-transform: uppercase;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 10px;
    }
  }
`;

export const TextAreaField = styled.textarea<Props>`
  background: transparent;
  color: ${({ theme }) => theme.colors.paragraph};
  font: ${({ theme }) => theme.fonts.small};

  width: 100%;
  height: 120px;
  padding: 12px;
  resize: none;

  border: 1px solid
    ${({ theme, error }) => (error ? theme.colors.error : theme.colors.info)};
  border-radius: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 12px;
  }
`;

export const Styled = {
  TextAreaFieldContainer,
  TextAreaField,
};
