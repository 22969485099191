import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../ui/components/Form/Button";
import { BenefitsNavigationButtons } from "../Sections/MainSection/NavigationBenefits";
import { Solution } from "../Sections/MainSection/types";
import { getSolutions } from "../Sections/MainSection/utils";
import { Styled } from "./styles";

export const ProductsBenefits: React.FC = () => {
  const [solution, setSolution] = useState<Solution>("telemedicine");
  const { t } = useTranslation();

  const handleSolutionChange = (solution: Solution) => {
    setSolution(solution);
  };

  const handleGetNextSolution = () => {
    setSolution((pervSolution) => {
      if (pervSolution === "telemedicine") {
        return "virtucare";
      }

      if (pervSolution === "virtucare") {
        return "fallSensor";
      }

      return "telemedicine";
    });
  };

  const handleRedirectToSolution = () => {
    if (solution === "fallSensor") {
      window.location.href = `/service/fall-sensor`;
      return;
    }

    window.location.href = `/service/${solution}`;
  };

  return (
    <>
      <BenefitsNavigationButtons
        handleSolutionChange={handleSolutionChange}
        handleGetNextSolution={handleGetNextSolution}
        solution={solution}
      />

      <Styled.ProductsBenefitsContainer>
        {getSolutions(solution).map((item, index) => {
          return (
            <Styled.ProductCard
              key={item.description}
              className={`benefit-${index}`}
            >
              <img src={item.icon} alt={item.description} />
              <span>{t(`home.main.solution.${item.description}`)}</span>
            </Styled.ProductCard>
          );
        })}

        <Styled.ButtonWrapper>
          <Button
            label={t("home.main.label.more")}
            variant="tertiary"
            size="small"
            onClick={handleRedirectToSolution}
          />
        </Styled.ButtonWrapper>
      </Styled.ProductsBenefitsContainer>
    </>
  );
};
