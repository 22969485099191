import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;

  margin: 80px 0 180px;

  border-radius: 10px;
  background: ${({ theme }) => theme.colors.shape};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 40px 0 80px;
  }

  &.mobile {
    justify-content: center;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin: 26px 0 52px;
      flex-direction: column;
    }
  }
`;

export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 496px;
  width: 100%;
  padding: 50px 24px 0;

  border-radius: 10px 0 0 10px;
  background: ${({ theme }) => theme.colors.gradient};
  color: white;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    max-width: 266px;
    padding: 22px 16px 0;
  }

  &.mobile {
    max-width: none;
    border-radius: 10px;
    padding-bottom: 16px;
  }

  > h3 {
    font: ${({ theme }) => theme.fonts.subTitle};
    margin-bottom: 36px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }

  > span {
    font: ${({ theme }) => theme.fonts.small};
    margin-bottom: 24px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 12px;
    }
  }
`;

export const InfoContactArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 6px;
  }

  > span {
    display: flex;
    align-items: center;
    gap: 12px;

    font: ${({ theme }) => theme.fonts.small};

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 10px;
      gap: 6px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 38px;

  width: 100%;
  padding: 50px 40px 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 26px 26px 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 20px;
    background: ${({ theme }) => theme.colors.shape};
    border-radius: 10px;
    margin-top: 20px;
    gap: 16px;
  }

  > h3 {
    font: ${({ theme }) => theme.fonts.subTitle};
    color: ${({ theme }) => theme.colors.paragraph};

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 16px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 14px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 80px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 40px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: auto;
`;

export const Styled = {
  FormContainer,
  InstructionsContainer,
  InfoContactArea,
  Form,
  Row,
  ButtonWrapper,
};
