import styled from "styled-components";

const FallSensorContent = styled.div`
  position: relative;
  z-index: 1;

  padding: 100px 80px 110px;
  margin-bottom: 40px;

  color: ${({ theme }) => theme.colors.paragraph};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 40px 60px 0px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 50px 40px 20px;
    margin-bottom: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 30px 26px 0px;
    margin-bottom: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 16px 24px 0px;
    margin-bottom: 22px;
  }

  > div {
    padding-inline: 70px;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl2}) {
      padding-inline: 0;
    }

    h1 {
      max-width: 448px;
      margin-bottom: 28px;
      font: ${({ theme }) => theme.fonts.title};

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 28px;
        max-width: 400px;
        margin-bottom: 16px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 26px;
        max-width: none;
        margin-bottom: 20px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 20px;
        margin-bottom: 8px;
        max-width: 240px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        line-height: 25px;
      }
    }

    p {
      max-width: 644px;
      margin-bottom: 28px;

      font: ${({ theme }) => theme.fonts.paragraph};
      font-size: 16px;

      @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
        font-size: 14px;
        max-width: 560px;
        margin-bottom: 20px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        max-width: none;
        margin-bottom: 32px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 12px;
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        font-size: 10px;
        line-height: 15px;
        margin-bottom: 18px;
      }
    }
  }
`;

export const MockupWrapper = styled.div`
  position: absolute;
  top: -38px;
  right: -104px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl2}) {
    right: -66px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    top: -20px;
    right: -40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    top: calc(100% - 74px);
    right: -14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: calc(100% - 76px);
    right: -12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    top: calc(100% - 60px);
    right: -10px;
  }

  img:first-child {
    margin-right: 12px;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl2}) {
      margin-right: 0;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: 320px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 220px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 168px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      width: 154px;
    }
  }

  img:last-child {
    @media (max-width: ${({ theme }) => theme.breakpoints.xl2}) {
      display: none;
    }
  }
`;

export const Styled = {
  FallSensorContent,
  MockupWrapper,
};
