import { VIRTUMED_WPP_NUMBER } from "../config/constants";

export const useContact = () => {
  const onContact = async () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=${VIRTUMED_WPP_NUMBER}`,
      "_blank"
    );
  };

  return { onContact };
};
