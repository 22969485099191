type FormatPhoneNumberProps = {
  countryCodeLength?: number;
  areaCodeLength?: number;
  phoneNumber: string;
};

export const formatterPhoneNumber = ({
  phoneNumber,
  countryCodeLength = 2,
  areaCodeLength = 2,
}: FormatPhoneNumberProps) => {
  return (
    "+ " +
    phoneNumber.substr(0, countryCodeLength) +
    " " +
    phoneNumber.substr(2, areaCodeLength) +
    " " +
    phoneNumber.substr(4, 5) +
    "-" +
    phoneNumber.substr(9, 4)
  );
};

// TODO: Implements this regex on contact form
// export const regexPhoneNumber = (phoneNumber: string) => {
//   phoneNumber = phoneNumber?.replace(/\D/g, "");
//   phoneNumber = phoneNumber?.replace(/^(\d{2})(\d)/g, "($1) $2");
//   phoneNumber = phoneNumber?.replace(/(\d)(\d{4})$/, "$1-$2");

//   return phoneNumber;
// };
