import styled from "styled-components";

export const ContactContainer = styled.section`
  padding: 90px 78px 0;
  color: ${({ theme }) => theme.colors.paragraph};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 60px 40px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 42px 26px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 24px 20px 0;
  }

  > * {
    transition: color 0.2s ease-in-out;
  }

  h1 {
    font: ${({ theme }) => theme.fonts.title};

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 30px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 18px;
    }
  }

  p {
    font: ${({ theme }) => theme.fonts.paragraph};
    max-width: 740px;
    margin-top: 8px;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 16px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 12px;
      max-width: none;
    }
  }
`;

export const Styled = {
  ContactContainer,
};
