import styled from "styled-components";

type Props = {
  iconColor: string;
};

const IconButton = styled.button<Props>`
  background: none;
  border: none;

  width: 30px;
  height: 30px;

  font-size: 30px;
  color: ${({ iconColor }) => iconColor};
`;

export const Styled = {
  IconButton,
};
