export const DefaultTheme = {
  colors: {
    primary: {
      overlay: "#F3EFFF",
      500: "#4B39B3",
    },

    secondary: {
      overlay: "#F9EFF5",
      500: "#D63F97",
    },

    tertiary: {
      light: "#26C1D7",

      overlay: "#F3F7FA",
      500: "#4095D9",
    },

    error: "#F14846",

    gradient:
      "linear-gradient(83.14deg, rgba(214, 63, 138, 0.7) 20.56%, rgba(38, 193, 215, 0.7) 81.22%);",
  },

  fonts: {
    title: '500 2.25rem "Poppins", sans-serif',
    subTitle: '500 1.25rem "Poppins", sans-serif',
    paragraph: '400 1.125rem "Poppins", sans-serif',
    small: '400 0.875rem "Poppins", sans-serif',
    info: '400 0.875rem "Poppins", sans-serif',
  },

  breakpoints: {
    xxs: "320px",
    xs: "375px",
    sm: "425px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xl2: "1440px",
    xl3: "2560px",
  },
};
