import styled from "styled-components";
import { getSizes, SizeTypes } from "./helper/getSizes";
import { getVariants, VariantTypes } from "./helper/getVariants";

type StyledProps = {
  variant: VariantTypes;
  size: SizeTypes;
};

export const ButtonContainer = styled.button<StyledProps>`
  padding: ${({ size }) => getSizes({ size }).padding};
  font: ${({ theme }) => theme.fonts.paragraph};
  font-size: ${({ size }) => getSizes({ size })["font-size"]};
  color: white;

  border: 2px solid;
  border-radius: 6px;

  border-color: ${({ variant, theme }) =>
    getVariants({ variant, theme })["border-color"]};
  background: ${({ variant, theme }) =>
    getVariants({ variant, theme })["background"]};
`;

export const Styled = {
  ButtonContainer,
};
