import styled from "styled-components";

type Props = {
  isCurrent: boolean;
};

const ProductsBenefitsButtons = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  overflow: hidden;
`;

const Button = styled.button<Props>`
  padding: 10px;

  font: ${({ theme }) => theme.fonts.small};
  color: ${({ isCurrent, theme }) =>
    isCurrent ? theme.colors.paragraph : "white"};
  background: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.shape : theme.colors.tertiary[500]};

  border: 2px solid transparent;
  border-bottom-color: ${({ theme, isCurrent }) =>
    isCurrent ? theme.colors.secondary[500] : "transparent"};

  transition: all 0.2s ease-in-out;
`;

/// Mobile
const NextButtonWrapper = styled.div`
  position: absolute;
  right: 24px;
  top: 16px;
  z-index: 3;

  display: flex;
  justify-content: space-between;

  width: calc(100% - 48px);

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    top: 8px;
  }

  > p {
    font: ${({ theme }) => theme.fonts.subTitle};
    color: ${({ theme }) => theme.colors.paragraph};
    font-size: 18px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 14px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 10px;
    }
  }

  > button {
    border: none;
    background: none;

    font-size: 28px;
    color: ${({ theme }) => theme.colors.tertiary[500]};

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 22px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 18px;
    }
  }
`;
//

export const Styled = {
  ProductsBenefitsButtons,
  Button,

  /// Mobile
  NextButtonWrapper,
};
