import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../../ui/components/Layout/Wrapper";
import { Styled } from "./styles";

type HowItWorksProps = {
  service: "telemedicine" | "virtucare" | "fall-sensor";
};

export const HowItWorks: React.FC<HowItWorksProps> = ({ service }) => {
  const { t } = useTranslation();

  return (
    <Styled.HowItWorksContainer>
      <Wrapper>
        <Styled.HowItWorksContent>
          <h2>{t(`services.${service}.solution.about`)}</h2>
          <p>{t(`services.${service}.solution.description`)}</p>
        </Styled.HowItWorksContent>
      </Wrapper>
    </Styled.HowItWorksContainer>
  );
};
