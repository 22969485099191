import { useLanguage } from "../../../../../hooks/useLanguage";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { Styled } from "./styles";

type SettingsProps = {
  alwaysWhiteNavigationLabel?: boolean;
};

export const Settings: React.FC<SettingsProps> = ({
  alwaysWhiteNavigationLabel = false,
}) => {
  const { handleChangeLanguage } = useLanguage();

  const changeToPt = () => {
    handleChangeLanguage("pt-BR");
  };

  const changeToEn = () => {
    handleChangeLanguage("en");
  };

  return (
    <Styled.SettingsContainer whiteLabel={alwaysWhiteNavigationLabel}>
      <div>
        <Styled.LanguageButton
          whiteLabel={alwaysWhiteNavigationLabel}
          onClick={changeToPt}
        >
          PT
        </Styled.LanguageButton>
        <span className="divider">|</span>
        <Styled.LanguageButton
          whiteLabel={alwaysWhiteNavigationLabel}
          onClick={changeToEn}
        >
          EN
        </Styled.LanguageButton>
      </div>

      <ThemeSwitcher />
    </Styled.SettingsContainer>
  );
};
