import styled from "styled-components";

export const SolutionCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 0 0.5rem 1rem;
  height: 230px;
  width: 408px;
  border-radius: 6px;

  background: ${({ theme }) => theme.colors.shape};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: auto;
    height: auto;
    padding-inline: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: auto;
    height: auto;
    padding-inline: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 220px;
    height: auto;
    padding-inline: 14px;
  }

  h3 {
    font: ${({ theme }) => theme.fonts.subTitle};
    margin-top: 50px;
    margin-bottom: 1.875rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 16px;
      margin: 40px 0 14px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 10px;
      margin: 26px 0 8px;
    }
  }

  span {
    text-align: center;
    max-width: 380px;
    font: ${({ theme }) => theme.fonts.small};

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 8px;
      line-height: 14px;
    }
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: -26px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 52px;

  border-radius: 6px;
  background: ${({ theme }) => theme.colors.secondary[500]};

  font-size: 20px;
  color: white;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    top: -24px;

    width: 36px;
    height: 36px;

    font-size: 16px;
  }
`;

export const Styled = {
  SolutionCardWrapper,
  IconWrapper,
};
