import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useScrollSmooth } from "../hooks/useScrollSmooth";
import { Contact } from "./Contact";
import { Home } from "./Home";
import { NotFound } from "./NotFound";
import { Profile } from "./Profile";
import { FallSensor } from "./Services/FallSensor";
import { Telemedicine } from "./Services/Telemedicine";
import { Virtucare } from "./Services/Virtucare";

export const App: React.FC = () => {
  useScrollSmooth();

  return (
    <Router>
      <Routes>
        <Route path="/profile/romulo" element={<Profile />} />
        <Route path="/profile/johnny" element={<Profile />} />

        <Route path="/service/virtucare" element={<Virtucare />} />
        <Route path="/service/telemedicine" element={<Telemedicine />} />
        <Route path="/service/fall-sensor" element={<FallSensor />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
        <Route path="/en-us" element={<Home />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
