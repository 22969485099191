import { useTranslation } from "react-i18next";
import { Styled } from "./styles";

type DropdownLinkProps = {
  url: string;
  label: string;
};

export const DropdownLink: React.FC<DropdownLinkProps> = ({ label, url }) => {
  const { t } = useTranslation();
  return (
    <Styled.DropdownLink key={url}>
      <a href={url}>{t(label)}</a>
    </Styled.DropdownLink>
  );
};
