import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Styled } from "./styles";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  ref: ForwardedRef<HTMLInputElement>;
  error?: FieldError;
};

export const InputField: React.FC<InputFieldProps> = forwardRef(
  ({ label, error, required, ...rest }, ref) => {
    const { t } = useTranslation();

    return (
      <Styled.InputFieldContainer error={!!error}>
        <label htmlFor={rest.id}>
          {label}
          <strong>{required && `*`}</strong>
        </label>
        <Styled.InputField {...rest} ref={ref} error={!!error} />
        {error && <span>{t(`global.form-erros.${error?.message}`)}</span>}
      </Styled.InputFieldContainer>
    );
  }
);
