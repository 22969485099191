import styled from "styled-components";
import { borderGradient } from "../../../utils";

const SolutionsItem = styled.div`
  position: relative;

  background: ${({ theme }) => borderGradient(theme)};
  border: 1px solid transparent;
  border-radius: 10px;

  padding: 20px 16px;
  margin-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 26px 16px;
    margin-bottom: 36px;
  }

  h2 {
    margin-bottom: 14px;
    font: ${({ theme }) => theme.fonts.subTitle};

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-bottom: 18px;
    }
  }

  p {
    font: ${({ theme }) => theme.fonts.small};
    font-size: 12px;
  }
`;

const GradientBar = styled.label`
  ::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 16px;

    width: 150px;
    height: 2px;

    background: ${({ theme }) => theme.colors.gradient};
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  bottom: -16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    bottom: -12px;
  }
`;

export const Styled = {
  SolutionsItem,
  GradientBar,
  ButtonWrapper,
};
