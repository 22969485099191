import { DefaultTheme } from "styled-components";

export type VariantTypes = "primary" | "secondary" | "tertiary" | "outlined";

type GetVariantesProps = {
  variant: VariantTypes;
  theme: DefaultTheme;
};

export const getVariants = ({ variant, theme }: GetVariantesProps) => {
  const variants = {
    primary: {
      "border-color": `${theme.colors.primary[500]}`,
      background: `${theme.colors.primary[500]}`,
    },
    secondary: {
      "border-color": `${theme.colors.secondary[500]}`,
      background: `${theme.colors.secondary[500]}`,
    },
    tertiary: {
      "border-color": `${theme.colors.tertiary[500]}`,
      background: `${theme.colors.tertiary[500]}`,
    },
    outlined: {
      "border-color": "white",
      background: "transparent",
    },
  };

  return variants[variant];
};
