import styled from "styled-components";

type Props = {
  whiteLabel: boolean;
};

const SettingsContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 1.75rem;

  .divider {
    margin: 0 0.5rem;
    color: ${({ theme, whiteLabel }) =>
      whiteLabel ? "white" : theme.colors.paragraph};
  }
`;

export const LanguageButton = styled.button<Props>`
  border: none;
  background: none;
  color: ${({ theme, whiteLabel }) =>
    whiteLabel ? "white" : theme.colors.paragraph};
  font: ${({ theme }) => theme.fonts.paragraph};
`;

export const Styled = {
  SettingsContainer,
  LanguageButton,
};
