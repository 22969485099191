import Icon1SVG from "../../../../assets/svg/solution/1.svg";
import Icon10SVG from "../../../../assets/svg/solution/10.svg";
import Icon2SVG from "../../../../assets/svg/solution/2.svg";
import Icon3SVG from "../../../../assets/svg/solution/3.svg";
import Icon4SVG from "../../../../assets/svg/solution/4.svg";
import Icon5SVG from "../../../../assets/svg/solution/5.svg";
import Icon6SVG from "../../../../assets/svg/solution/6.svg";
import Icon7SVG from "../../../../assets/svg/solution/7.svg";
import Icon8SVG from "../../../../assets/svg/solution/8.svg";
import Icon9SVG from "../../../../assets/svg/solution/9.svg";

export const telemedicine_solutions = [
  { icon: Icon1SVG, title: "telemedicine.solution.1" },
  { icon: Icon2SVG, title: "telemedicine.solution.2" },
  { icon: Icon3SVG, title: "telemedicine.solution.3" },
  { icon: Icon4SVG, title: "telemedicine.solution.4" },
  { icon: Icon5SVG, title: "telemedicine.solution.5" },
  { icon: Icon6SVG, title: "telemedicine.solution.6" },
  { icon: Icon7SVG, title: "telemedicine.solution.7" },
  { icon: Icon8SVG, title: "telemedicine.solution.8" },
  { icon: Icon9SVG, title: "telemedicine.solution.9" },
  { icon: Icon10SVG, title: "telemedicine.solution.10" },
];

export const virtucare_solutions = [
  { icon: Icon1SVG, title: "virtucare.solution.1" },
  { icon: Icon2SVG, title: "virtucare.solution.2" },
  { icon: Icon3SVG, title: "virtucare.solution.3" },
  { icon: Icon4SVG, title: "virtucare.solution.4" },
  { icon: Icon5SVG, title: "virtucare.solution.5" },
];

export const virtucare_fall_sensor = [
  { icon: Icon1SVG, title: "fall-sensor.solution.1" },
  { icon: Icon2SVG, title: "fall-sensor.solution.2" },
  { icon: Icon3SVG, title: "fall-sensor.solution.3" },
  { icon: Icon4SVG, title: "fall-sensor.solution.4" },
];

export const MOCKS = {
  telemedicine_solutions,
  virtucare_solutions,
  virtucare_fall_sensor,
};
