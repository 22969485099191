import styled from "styled-components";

export const MainSectionContainer = styled.section`
  position: relative;
  z-index: 1;

  padding: 0 80px;
  margin: 110px 0 130px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 52px 0 28px;
    padding-inline: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 28px 0 16px;
    padding-inline: 0px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin: 22px 0 14px;
  }

  > h1 {
    max-width: 500px;
    font: ${({ theme }) => theme.fonts.title};
    color: white;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 36px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 26px;
      line-height: 30px;
      padding-inline: 24px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 20px;
      line-height: 22px;
    }

    > strong {
      color: ${({ theme }) => theme.colors.tertiary[500]};
    }
  }

  > p {
    margin-top: 30px;
    max-width: 560px;
    font: ${({ theme }) => theme.fonts.paragraph};
    color: white;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 12px;
      margin-top: 12px;
      padding-inline: 24px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      margin-top: 8px;
    }
  }
`;

export const ProductsBenefitsWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 110px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    padding-inline: 8px;
  }
`;

export const Styled = {
  MainSectionContainer,
  ProductsBenefitsWrapper,
};
