import { useTranslation } from "react-i18next";
import { useIsCurrentPage } from "../../../../../hooks/useIsCurrentPage";
import { Styled } from "./styles";

type NavigationLinkProps = {
  url: string;
  label: string;
};

export const NavigationLink: React.FC<NavigationLinkProps> = ({
  url,
  label,
}) => {
  const { t } = useTranslation();
  const { isCurrentPage } = useIsCurrentPage({ url, label });

  return (
    <Styled.NavigationLink isCurrent={isCurrentPage}>
      <a id={label} href={url}>
        {t(`global.header.${label}`)}
      </a>
      <label htmlFor={label} className="link-highlight" />
    </Styled.NavigationLink>
  );
};
