import HomeBannerDarkPNG from "../../assets/png/home-banner-dark.png";
import HomeBannerLightPNG from "../../assets/png/home-banner.png";
import { Layout } from "../../ui/components/Layout";
import { ConnectedSection } from "./components/Sections/ConnectedSection";
import { MainSection } from "./components/Sections/MainSection";
import { SolutionsSection } from "./components/Sections/SolutionsSection";

export const Home: React.FC = () => {
  const banner = {
    light: HomeBannerLightPNG,
    dark: HomeBannerDarkPNG,
  };

  return (
    <Layout
      headerConfig={{
        headerBanner: banner,
        alwaysWhiteNavigationLabel: true,
      }}
    >
      <div>
        <MainSection />
        <SolutionsSection />
        <ConnectedSection />
      </div>
    </Layout>
  );
};
