import styled from "styled-components";

const HowItWorksContainer = styled.div`
  background: ${({ theme }) => theme.colors.shape};
  height: 224px;
  width: 100%;
  margin: 112px 0 136px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 194px;
    margin-bottom: 90px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 194px;
    margin-top: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    background: transparent;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin: 0px 0 32px;
  }
`;

const HowItWorksContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 132px;

  height: 100%;
  padding: 0 80px;

  color: ${({ theme }) => theme.colors.paragraph};

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-inline: 60px;
    gap: 102px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-inline: 40px;
    gap: 60px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
    padding-inline: 26px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-inline: 24px;
    gap: 8px;
  }

  h2 {
    font: ${({ theme }) => theme.fonts.subTitle};
    font-size: 30px;
    max-width: 425px;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 26px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 24px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 20px;
      max-width: 260px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 16px;
      max-width: 160px;
    }
  }

  p {
    font: ${({ theme }) => theme.fonts.paragraph};

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      font-size: 16px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 14px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 10px;
    }
  }
`;

export const Styled = {
  HowItWorksContainer,
  HowItWorksContent,
};
