import styled from "styled-components";

const DropdownLink = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.bg};
  padding: 2px 0;

  > a {
    text-decoration: none;
    font: ${({ theme }) => theme.fonts.small};
    color: ${({ theme }) => theme.colors.paragraph};
    text-transform: capitalize;
    font-size: 12px;
  }
`;

export const Styled = {
  DropdownLink,
};
