import styled from "styled-components";

export const SolutionsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 88px;
  color: ${({ theme }) => theme.colors.paragraph};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 0px;
  }

  h2 {
    margin-bottom: 26px;
    font: ${({ theme }) => theme.fonts.title};

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-inline: 60px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-inline: 26px;
      font-size: 18px;
      margin-top: 16px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding-inline: 24px;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 8px;
    }
  }

  p {
    max-width: 820px;

    text-align: center;
    font: ${({ theme }) => theme.fonts.paragraph};

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-inline: 60px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-inline: 26px;
      font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding-inline: 24px;
      font-size: 10px;
    }
  }
`;

export const CarrouselSolutionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.875rem;

  margin: 100px 0 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 48px 26px 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin: 60px 24px 24px;
  }
`;

export const Styled = {
  SolutionsSection,
  CarrouselSolutionWrapper,
};
