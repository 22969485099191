import { useTranslation } from "react-i18next";
import { Button } from "../../../../../ui/components/Form/Button";
import { Styled } from "./styles";

type SolutionItemProps = {
  name: string;
  url: string;
};

export const SolutionItem: React.FC<SolutionItemProps> = ({ name, url }) => {
  const { t } = useTranslation();

  const handleRedirectToSolution = () => {
    if (name === "fallSensor") {
      window.location.href = `/service/fall-sensor`;
      return;
    }

    window.location.href = `/service/${name}`;
  };

  return (
    <Styled.SolutionsItem>
      <Styled.GradientBar />
      <h2>{t(`profile.solutions.${name}.title`)}</h2>
      <p>{t(`profile.solutions.${name}.description`)}</p>

      <Styled.ButtonWrapper>
        <Button
          label={t(`profile.solutions.label`)}
          variant="secondary"
          size="xs"
          onClick={handleRedirectToSolution}
        />
      </Styled.ButtonWrapper>
    </Styled.SolutionsItem>
  );
};
