import type { DefaultTheme } from "styled-components";
import type { HeaderBackgroundVariantsType, HeaderSizeType } from "../types";

export const getHeaderSize = (type: HeaderSizeType) => {
  const sizes = {
    small: "20.25rem",
    medium: "35rem",
    large: "43.75rem",
  };

  return sizes[type];
};

type GetHeaderBackgroundColorProps = {
  backgroundVariant: HeaderBackgroundVariantsType;
  theme: DefaultTheme;
  isDarkMode: boolean;
};

export const getHeaderBackgroundColor = ({
  backgroundVariant,
  theme,
  isDarkMode,
}: GetHeaderBackgroundColorProps) => {
  if (isDarkMode) {
    return theme.colors.shape;
  }

  const colors = {
    shape: theme.colors.shape,
    primary: theme.colors.primary.overlay,
    secondary: theme.colors.secondary.overlay,
    tertiary: theme.colors.tertiary.overlay,
    transparent: "transparent",
  };

  return colors[backgroundVariant];
};
