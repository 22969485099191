import { createGlobalStyle } from "styled-components";
import "swiper/css";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    transition: color .2s ease-in-out;
    transition: background-color .2s ease-in-out;

    scroll-behavior: smooth;
  }

  html,
  body {
    overflow-x: hidden;
  }

  button {
    cursor: pointer;

    transition: filter 200ms ease-in-out;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;
