import AvatarPNG from "../../assets/png/avatar.png";
import RomuloAvatarPNG from "../../assets/png/romulo-avatar.png";

export type ProfileType = {
  avatar: string;
  name: string;
  title: string;
  phone: number;
  email: string;
};

export const mockProfile = {
  johnny: {
    avatar: AvatarPNG,
    name: "Johnny K",
    title: "CTO & Co-Founder",
    phone: 5511994681238,
    email: "johnny@virtumed.com.br",
  },
  romulo: {
    avatar: RomuloAvatarPNG,
    name: "Romulo Nascimento",
    title: "CEO & Co-Founder",
    phone: 5511961938766,
    email: "romulo@virtumed.com.br",
  },
};
