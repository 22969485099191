import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DarkLogoSVG from "../../../../assets/svg/dark-logo.svg";
import LightLogoSVG from "../../../../assets/svg/light-logo.svg";
import { useTheme } from "../../../../hooks/useTheme";
import { Dropdown } from "../../Form/Dropdown";
import { Wrapper } from "../Wrapper";
import MockUrls from "./mocks/urls.json";
import { NavigationLink } from "./NavigationLink";
import { Settings } from "./Settings";
import { Styled } from "./styles";

type HeaderProps = {
  alwaysWhiteNavigationLabel: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  alwaysWhiteNavigationLabel,
}) => {
  const [logo, setLogo] = useState(LightLogoSVG);

  const { t } = useTranslation();
  const { isDarkMode } = useTheme();

  useEffect(() => {
    if (isDarkMode || alwaysWhiteNavigationLabel) {
      setLogo(LightLogoSVG);
      return;
    }

    setLogo(DarkLogoSVG);
  }, [isDarkMode, alwaysWhiteNavigationLabel]);

  return (
    <Wrapper>
      <Styled.HeaderContainer>
        <img src={logo} alt="virtumed" />

        <Styled.NavWrapper>
          <nav>
            <Styled.LinksList whiteLabel={alwaysWhiteNavigationLabel}>
              <NavigationLink label="Home" url="/" />

              <Dropdown
                label={t(`global.header.Services`)}
                whiteLabel={alwaysWhiteNavigationLabel}
              />

              {MockUrls.map((item) => (
                <NavigationLink key={item.label} {...item} />
              ))}
            </Styled.LinksList>
          </nav>
          <Settings alwaysWhiteNavigationLabel={alwaysWhiteNavigationLabel} />
        </Styled.NavWrapper>
      </Styled.HeaderContainer>
    </Wrapper>
  );
};
