import styled from "styled-components";

type Props = {
  withMockup: boolean;
};

export const FaqBannerContainer = styled.section`
  height: 392px;
  margin-bottom: 160px;

  background: ${({ theme }) => theme.colors.gradient};
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-bottom: 40px;
    height: 368px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 30px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    margin-bottom: none;
    height: 274px;
  }
`;

export const FaqBannerContent = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding: 0 60px;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-inline: 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-inline: 24px;
  }
`;

export const FaqBannerTextsWrapper = styled.div`
  max-width: 652px;
  color: white;

  .divider {
    ::before {
      content: "";
      display: block;
      width: 70px;
      height: 2px;
      background: white;
      margin-bottom: 14px;
    }
  }

  h3 {
    font: ${({ theme }) => theme.fonts.subTitle};
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 14px;
    }
  }

  p {
    font: ${({ theme }) => theme.fonts.paragraph};
    margin-bottom: 30px;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 14px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      font-size: 10px;
    }
  }
`;

export const FaqBannerImagesWrapper = styled.div<Props>`
  position: absolute;
  right: 20px;
  display: flex;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    opacity: 0.4;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    display: none;
  }

  .mockup {
    position: absolute;
    right: 70px;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      right: 20px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: none;
    }
  }
`;

export const Styled = {
  FaqBannerContainer,
  FaqBannerContent,
  FaqBannerTextsWrapper,
  FaqBannerImagesWrapper,
};
