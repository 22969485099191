import { useTranslation } from "react-i18next";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Wrapper } from "../../../../../ui/components/Layout/Wrapper";
import { mockPartners } from "./mocks/partners";
import { Styled } from "./styles";

export const ConnectedSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.ConnectedSection id="clients">
      <h2>{t("home.connected.title")}</h2>
      <p>{t("home.connected.description")}</p>

      <Styled.CarrouselContainer>
        <Wrapper>
          <Swiper
            spaceBetween={80}
            slidesPerView="auto"
            resizeObserver
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            modules={[Autoplay]}
          >
            {mockPartners.map((partner) => (
              <SwiperSlide key={partner.name}>
                <Styled.Partner key={partner.name}>
                  {partner.logo}
                </Styled.Partner>
              </SwiperSlide>
            ))}
          </Swiper>
        </Wrapper>
      </Styled.CarrouselContainer>
    </Styled.ConnectedSection>
  );
};
