import { useTranslation } from "react-i18next";
import { BsLinkedin } from "react-icons/bs";
import DarkLogoSVG from "../../../../assets/svg/dark-logo.svg";
import LightLogoSVG from "../../../../assets/svg/light-logo.svg";
import { useTheme } from "../../../../hooks/useTheme";
import { Wrapper } from "../Wrapper";
import MockLinks from "./mocks/links.json";
import { Styled } from "./styles";

const arrMock = new Array(3).fill(MockLinks);

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();

  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <Styled.FooterContainer>
        <Styled.ArticleContainer>
          <img src={isDarkMode ? LightLogoSVG : DarkLogoSVG} alt="virtumed" />
          <p>{t("global.footer.Virtumed")}</p>

          <Styled.SocialContainer>
            {/* <a href="#">
              <BsInstagram />
            </a> */}
            <a
              href="https://www.linkedin.com/company/virtumedbr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin />
            </a>
            {/* <a href="#">
              <BsTwitter />
            </a> */}
          </Styled.SocialContainer>
        </Styled.ArticleContainer>

        <Styled.LinksContainer>
          {arrMock.map((item, index) => {
            return (
              <div key={index}>
                {/* <strong>{t(`global.footer.${item.title}`)}</strong>
                <ul>
                  {item.body.map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        <a href={item.url}>
                          {t(`global.footer.${item.label}`)}
                        </a>
                      </li>
                    );
                  })}
                </ul> */}
              </div>
            );
          })}
        </Styled.LinksContainer>

        <Styled.CopyrightContainer>
          <p>
            &copy; {t("global.footer.copyright")} - {year}
          </p>
        </Styled.CopyrightContainer>
      </Styled.FooterContainer>
    </Wrapper>
  );
};
