import { useTranslation } from "react-i18next";
import NotFoundPNG from "../../assets/png/not-found.png";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Button } from "../../ui/components/Form/Button";
import { Styled } from "./styles";

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  const handleRedirect = () => {
    window.location.href = "/";
  };

  return (
    <Styled.NotFoundContainer>
      <img src={NotFoundPNG} alt="404 not found" />
      <h1>{t(`Opps! Page Not Found`)}</h1>

      <Button
        label="Voltar para a Home"
        onClick={handleRedirect}
        size={isMobile ? "xs" : "medium"}
      />
    </Styled.NotFoundContainer>
  );
};
