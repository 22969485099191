import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoClose, IoMenu } from "react-icons/io5";
import Modal from "react-modal";
import DarkLogoSVG from "../../../../../assets/svg/dark-logo.svg";
import LightLogoSVG from "../../../../../assets/svg/light-logo.svg";
import { useTheme } from "../../../../../hooks/useTheme";
import { IconButton } from "../../../Form/IconButton";
import { Settings } from "../../Header/Settings";
import { Styled } from "./styles";

type HeaderMobileProps = {
  alwaysWhiteNavigationLabel: boolean;
};

Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "10",
  } as React.CSSProperties,

  content: {
    position: "fixed",
    width: "100vw",
    maxHeight: "100vh",
    height: "100%",
    padding: "0",
    border: "none",
    borderRadius: "0",
    inset: "0",
  } as React.CSSProperties,
};

export const HeaderMobile: React.FC<HeaderMobileProps> = ({
  alwaysWhiteNavigationLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const [logo, setLogo] = useState(LightLogoSVG);
  const [insideLogo, setInsideLogo] = useState(LightLogoSVG);
  const { isDarkMode } = useTheme();

  const year = new Date().getFullYear();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isDarkMode || alwaysWhiteNavigationLabel) {
      setLogo(LightLogoSVG);
    } else {
      setLogo(DarkLogoSVG);
    }
  }, [isDarkMode, alwaysWhiteNavigationLabel]);

  useEffect(() => {
    if (isDarkMode) {
      setInsideLogo(LightLogoSVG);
    } else {
      setInsideLogo(DarkLogoSVG);
    }
  }, [isDarkMode]);

  return (
    <Styled.HeaderContainer
      alwaysWhiteNavigationLabel={alwaysWhiteNavigationLabel}
    >
      <img src={logo} alt="Logo" />

      {isOpen ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="Navigation menu"
          style={customStyles}
        >
          <Styled.HeaderContent>
            <Styled.Top>
              <img src={insideLogo} alt="Logo" />
              <IconButton
                iconColor={isDarkMode ? "white" : "black"}
                icon={<IoClose />}
                onClick={onClose}
              />
            </Styled.Top>

            <Styled.NavLinksContainer>
              <a href="/">{t(`global.header.Home`)}</a>
              <a href="/service/telemedicine">
                {t(`global.header.Telemedicine`)}
              </a>
              <a href="/service/virtucare">Virtucare</a>
              <a href="/service/fall-sensor">
                {t(`global.header.fall-sensor`)}
              </a>
              <a href="/#clients">{t(`global.header.Clients`)}</a>
              <a href="/contact">{t(`global.header.Contact`)}</a>
            </Styled.NavLinksContainer>

            <Styled.SettingWrapper>
              <Settings />
            </Styled.SettingWrapper>

            <Styled.Footer>
              &copy; {t(`global.footer.copyright`)} - {year}
            </Styled.Footer>
          </Styled.HeaderContent>
        </Modal>
      ) : (
        <IconButton
          iconColor={
            alwaysWhiteNavigationLabel || isDarkMode ? "white" : "black"
          }
          icon={<IoMenu />}
          onClick={onOpen}
        />
      )}
    </Styled.HeaderContainer>
  );
};
