import React, { HTMLAttributes } from "react";
import { Styled } from "./styles";

type IconButtonProps = HTMLAttributes<HTMLButtonElement> & {
  icon: any;
  iconColor?: string;
};

export const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  iconColor = "#fff",
  ...rest
}) => {
  return (
    <Styled.IconButton iconColor={iconColor} {...rest}>
      {Icon}
    </Styled.IconButton>
  );
};
