import { useTranslation } from "react-i18next";
import Mockup1PNG from "../../../assets/png/virtucare-mockup-1.png";
import Mockup2PNG from "../../../assets/png/virtucare-mockup-2.png";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { useContact } from "../../../hooks/useWpp";
import { Button } from "../../../ui/components/Form/Button";
import { Layout } from "../../../ui/components/Layout";
import { Wrapper } from "../../../ui/components/Layout/Wrapper";
import { HowItWorks } from "../components/HowItWorks";
import { Solutions } from "../components/Solutions";
import { SolutionsMobile } from "../components/Solutions/mobile";
import { Styled } from "./styles";

export const Virtucare: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();
  const { onContact } = useContact();

  return (
    <Layout
      headerConfig={{
        headerBackgroundVariant: "secondary",
        headerSize: "medium",
      }}
      className="with-larger-banner"
    >
      <Styled.VirtucareContent>
        <Wrapper>
          <h1>{t("services.virtucare.title")}</h1>
          <p>{t("services.virtucare.description")}</p>

          <Button
            label={t("services.virtucare.button-label")}
            size={isMobile ? "xs" : "small"}
            onClick={onContact}
          />

          <Styled.MockupWrapper>
            <img src={Mockup1PNG} alt="mockup" />
            <img src={Mockup2PNG} alt="mockup" />
          </Styled.MockupWrapper>
        </Wrapper>
      </Styled.VirtucareContent>

      {isMobile ? (
        <SolutionsMobile service="virtucare" gridColumns={2} />
      ) : (
        <Solutions service="virtucare" />
      )}

      <HowItWorks service="virtucare" />
    </Layout>
  );
};
