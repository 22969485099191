import { useTranslation } from "react-i18next";
import { MdDoubleArrow } from "react-icons/md";
import { IconButton } from "../../../../../../ui/components/Form/IconButton";
import type { BenefitsNavigationButtonsProps } from "../types";
import { Styled } from "./styles";

export const BenefitsNavigationButtons: React.FC<
  BenefitsNavigationButtonsProps
> = ({
  handleSolutionChange,
  solution,
  handleGetNextSolution,
  isMobile = false,
}) => {
  const { t } = useTranslation();

  const labelToTranslation =
    solution === "fallSensor" ? "fall-sensor" : solution;

  return (
    <>
      {isMobile ? (
        <Styled.NextButtonWrapper>
          <p>{t(`home.main.label.${labelToTranslation}`)}</p>

          <IconButton
            icon={<MdDoubleArrow />}
            onClick={handleGetNextSolution}
          />
        </Styled.NextButtonWrapper>
      ) : (
        <Styled.ProductsBenefitsButtons>
          <Styled.Button
            isCurrent={solution === "telemedicine"}
            onClick={() => handleSolutionChange("telemedicine")}
          >
            {t("home.main.label.telemedicine")}
          </Styled.Button>

          <Styled.Button
            isCurrent={solution === "virtucare"}
            onClick={() => handleSolutionChange("virtucare")}
          >
            {t("home.main.label.virtucare")}
          </Styled.Button>

          <Styled.Button
            isCurrent={solution === "fallSensor"}
            onClick={() => handleSolutionChange("fallSensor")}
          >
            {t("home.main.label.fall-sensor")}
          </Styled.Button>
        </Styled.ProductsBenefitsButtons>
      )}
    </>
  );
};
