import styled from "styled-components";

type Props = {
  alwaysWhiteNavigationLabel: boolean;
};

const HeaderContainer = styled.header<Props>`
  position: relative;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  padding: 40px 40px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 26px 26px 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding: 26px 24px 0;
  }

  > img {
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 120px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      width: 100px;
    }
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.shape};
  height: 100%;

  padding: 20px 40px 30px;
`;

const Top = styled.div`
  display: flex;
  width: 100%;

  > img {
    max-width: 160px;
  }

  > button {
    margin-left: auto;
  }
`;

const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: auto; ;
`;

const Footer = styled.div`
  margin-top: 24px;
  padding: 12px 16px 0;

  text-align: center;
  color: ${({ theme }) => theme.colors.info};
  font: ${({ theme }) => theme.fonts.info};

  border-top: 1px solid ${({ theme }) => theme.colors.info};
`;

const NavLinksContainer = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 6px;

  padding: 60px 0;

  a {
    color: ${({ theme }) => theme.colors.paragraph};
    font: ${({ theme }) => theme.fonts.paragraph};
    text-decoration: none;

    ::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Styled = {
  HeaderContainer,
  HeaderContent,
  Top,
  SettingWrapper,
  Footer,
  NavLinksContainer,
};
