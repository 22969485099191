import styled from "styled-components";

type Props = {
  isCurrentPage: boolean;
  whiteLabel: boolean;
};

const DropdownContainer = styled.div<Props>`
  display: flex;

  position: relative;
  z-index: 3;

  > button {
    font: ${({ theme }) => theme.fonts.paragraph};
    color: ${({ theme, whiteLabel }) =>
      whiteLabel ? "white" : theme.colors.paragraph};

    background: none;
    border: none;

    border-bottom: ${({ isCurrentPage, theme }) =>
      isCurrentPage ? `2px solid ${theme.colors.secondary[500]}` : "none"};
    padding-bottom: ${({ isCurrentPage }) => (isCurrentPage ? "2px" : "0")};
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  z-index: 3;
  top: calc(100% + 10px);
  left: -34px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 142px;
  padding: 12px 16px;
  border-radius: 6px;
  list-style: none;

  background: ${({ theme }) => theme.colors.shape};
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
`;

export const Styled = {
  DropdownContainer,
  Dropdown,
};
