import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LightLogoSVG from "../../assets/svg/light-logo.svg";
import { Settings } from "../../ui/components/Layout/Header/Settings";
import { Wrapper } from "../../ui/components/Layout/Wrapper";
import { AboutVirtumed } from "./components/AboutVitumed";
import { ProfileCard } from "./components/ProfileCard";
import { SolutionsList } from "./components/SolutionsList";
import { mockProfile, ProfileType } from "./mocks";
import { Styled } from "./styles";

export const Profile: React.FC = () => {
  const [profileData, setProfileData] = useState<ProfileType>();
  const { t } = useTranslation();

  useEffect(() => {
    const profile = window.location.pathname.split("/")[2];

    if (profile === "romulo" || profile === "johnny") {
      setProfileData(mockProfile[profile]);
    }
  }, []);

  return (
    <Styled.ProfileContainer>
      <Styled.HeaderContainer>
        <Styled.SettingsWrapper className="top">
          <Settings alwaysWhiteNavigationLabel />
        </Styled.SettingsWrapper>
        <Wrapper>
          <img src={LightLogoSVG} alt="virtumed" />
          <h1>
            {t(`profile.slogan`)}
            <strong>{t(`profile.slogan-highlight`)}</strong>
          </h1>
        </Wrapper>
      </Styled.HeaderContainer>

      <Styled.Bar />

      <Wrapper>
        <Styled.ProfileContent>
          <Styled.ProfileCardWrapper>
            {profileData && <ProfileCard data={profileData} />}
          </Styled.ProfileCardWrapper>

          <SolutionsList />

          <Styled.AboutVirtumedWrapper>
            <AboutVirtumed />
            <Styled.SettingsWrapper>
              <Settings />
            </Styled.SettingsWrapper>
          </Styled.AboutVirtumedWrapper>
        </Styled.ProfileContent>
      </Wrapper>
    </Styled.ProfileContainer>
  );
};
