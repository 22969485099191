import { HTMLAttributes } from "react";
import { SizeTypes } from "./helper/getSizes";
import { VariantTypes } from "./helper/getVariants";
import { Styled } from "./styles";

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
  label: string;
  variant?: VariantTypes;
  size?: SizeTypes;
};

export const Button: React.FC<ButtonProps> = ({
  label,
  variant = "primary",
  size = "medium",
  ...rest
}) => {
  return (
    <Styled.ButtonContainer variant={variant} size={size} {...rest}>
      {label}
    </Styled.ButtonContainer>
  );
};
