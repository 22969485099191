import AnimarePNG from "../../../../../../assets/png/animare.png";
import DoctorgramPNG from "../../../../../../assets/png/doctorgram.png";
import HolistePNG from "../../../../../../assets/png/holiste.png";
import IntensiViewPNG from "../../../../../../assets/png/intensiview.png";
import UnimedBelemPNG from "../../../../../../assets/png/unimed-belem.png";

const mockPartners = [
  {
    name: "Holiste",
    logo: <img src={HolistePNG} alt="Holiste" />,
  },
  {
    name: "Doctorgram",
    logo: <img src={DoctorgramPNG} alt="Doctorgram" />,
  },
  {
    name: "Animare",
    logo: <img src={AnimarePNG} alt="Animare" />,
  },
  {
    name: "IntensiView",
    logo: <img src={IntensiViewPNG} alt="IntensiViewPNG" />,
  },
  {
    name: "Unimed Belém",
    logo: <img src={UnimedBelemPNG} alt="IntensiViewPNG" />,
  },
];

export { mockPartners };
