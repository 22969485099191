import styled from "styled-components";

export const ConnectedSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 0 20px;

  color: ${({ theme }) => theme.colors.paragraph};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 36px 0 10px;
  }

  h2 {
    font: ${({ theme }) => theme.fonts.title};
    margin-bottom: 28px;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-inline: 60px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-inline: 26px;
      font-size: 18px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding-inline: 24px;
      font-size: 14px;
    }
  }

  p {
    font: ${({ theme }) => theme.fonts.paragraph};
    text-align: center;
    max-width: 51.25rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding-inline: 60px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding-inline: 26px;
      font-size: 12px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.xs}) {
      padding-inline: 24px;
      font-size: 10px;
    }
  }
`;

export const CarrouselContainer = styled.div`
  height: 11.375rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.shape};
  margin: 70px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 32px 0 40px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 8rem;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 300px;
    height: 11.375rem;

    @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
      width: 380px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      width: 470px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 350px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 100%;
      height: 8rem;
    }
  }
`;

export const Partner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Styled = {
  ConnectedSection,
  CarrouselContainer,
  Partner,
};
